import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import makeCancellablePromise from 'make-cancellable-promise';
import { toast } from 'react-toastify';
import axios from 'axios';
import { RiCloseCircleFill, RiSearchLine, RiArrowDownSLine, RiCreativeCommonsLine, RiCreativeCommonsByLine, RiCreativeCommonsNcLine, RiCreativeCommonsSaLine } from 'react-icons/ri';

import { ReactComponent as Logo } from './../assets/img/logo.svg';

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      pageLoad: true,
      province: [],
      provinceSearch: [],
      search: '',
      countPro: 0,
      countCit: 0,
      countDis: 0,
      countProSearch: 0,
      countCitSearch: 0,
      countDisSearch: 0
    }

    this.copy = 2024;
    this.today = new Date();

    this.cancellableProvince = '';
    this.cancellableCity = '';
    this.cancellableDistrict = '';
    
    this.inputChange = this.inputChange.bind(this);
    this.openCity = this.openCity.bind(this);
    this.openDistrict = this.openDistrict.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }
  
  componentDidMount(){
    this.cancellableProvince = makeCancellablePromise(
      axios.get('https://api.allorigins.win/raw?url=' + encodeURIComponent('https://pro.rajaongkir.com/api/province?key=7f752d10400f15469148e8a1802d311f'))
    );

    this.cancellableProvince.promise.then((resultProvince) => {
      let listProvince = [];
      
      resultProvince.data.rajaongkir.results.forEach((valPro, indPro) => {
        listProvince.push({
          value: valPro.province_id,
          label: valPro.province,
          city: [],
          town: 0,
          regency: 0,
          loading: false,
          open: false
        });
      });
      
      this.setState({
        province: listProvince,
        countPro: resultProvince.data.rajaongkir.results.length,
        pageLoad: false
      });
    }).catch((error) => {
      console.error(error);
      toast.error(() => (<>{error.code}<span>{error.message}</span></>));

      this.setState({ pageLoad: false });
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(
      this.state.search !== prevState.search ||
      this.state.province !== prevState.province
    ){
      let listProvince = [...this.state.province],
          listSearch = [],
          proCount = 0,
          citCount = 0,
          disCount = 0;

      if(this.state.search){
        listProvince.forEach((valPro, indPro) => {
          if(valPro.label.toLowerCase().includes(this.state.search.toLowerCase())){
            listSearch.push(valPro);
            proCount++;
          }else{
            valPro.city.forEach((valCit, indCit) => {
              if(valCit.label.toLowerCase().includes(this.state.search.toLowerCase())){
                const indexPro = listSearch.findIndex(x => x.value === valPro.value);

                if(indexPro === -1){
                  let changePro = JSON.parse(JSON.stringify(valPro));

                  changePro.city = [];
                  changePro.city.push(valCit)
                  listSearch.push(changePro);
                  // proCount++;
                  citCount++;
                }else{
                  const indexCity = listSearch[indexPro].city.findIndex(x => x.value === valCit.value);

                  if(indexCity === -1){
                    listSearch[indexPro].city.push(valCit);
                    citCount++;
                  }
                }
              }else{
                valCit.district.forEach((valdis, indDis) => {
                  if(valdis.label.toLowerCase().includes(this.state.search.toLowerCase())){
                    const indexPro = listSearch.findIndex(x => x.value === valPro.value);
    
                    if(indexPro === -1){
                      let changePro = JSON.parse(JSON.stringify(valPro)),
                          changeCit = JSON.parse(JSON.stringify(valCit));
                          
                      changeCit.district = [];
                      changeCit.district.push(valdis);
                      
                      changePro.city = [];
                      changePro.city.push(changeCit);

                      listSearch.push(changePro);
                      // proCount++;
                      // citCount++;
                      disCount++;
                    }else{
                      const indexCity = listSearch[indexPro].city.findIndex(x => x.value === valCit.value);
    
                      if(indexCity === -1){
                        let changeCit = JSON.parse(JSON.stringify(valCit));

                        changeCit.district = [];
                        changeCit.district.push(valdis);

                        listSearch[indexPro].city.push(changeCit);
                        // citCount++;
                        disCount++;
                      }else{
                        const indexDis = listSearch[indexPro].city[indexCity].district.findIndex(x => x.value === valdis.value);

                        if(indexDis === -1){
                          listSearch[indexPro].city[indexCity].district.push(valdis);
                          disCount++;
                        }
                      }
                    }
                  }
                });
              }
            });
          }
        });
      }else{
        listSearch = listProvince;
        proCount = this.state.countPro;
        citCount = this.state.countCit;
        disCount = this.state.countDis;
      }

      this.setState({
        provinceSearch: listSearch,
        countProSearch: proCount,
        countCitSearch: citCount,
        countDisSearch: disCount
      });
    }
  }

  componentWillUnmount(){
    if(this.cancellableProvince){
      this.cancellableProvince.cancel();
    }
    
    if(this.cancellableCity){
      this.cancellableCity.cancel();
    }

    if(this.cancellableDistrict){
      this.cancellableDistrict.cancel();
    }
  }

  inputChange(event){
    this.setState({ [event.target.name]: event.target.value });
  }

  openCity(event){
    let listCity = [],
        listProvince = [...this.state.province],
        theProvince = '';

    const indexProvince = listProvince.findIndex(x => x.value === event.target.dataset.idprovince);

    theProvince = listProvince[indexProvince];

    if(theProvince.city.length){
      if(theProvince.open){
        theProvince.open = false;
      }else{
        theProvince.open = true;
      }
      
      this.setState({ province: listProvince });
    }else{
      theProvince.open = true;
      theProvince.loading = true;

      this.setState({ province: listProvince });
  
      this.cancellableCity = makeCancellablePromise(
        axios.get('https://api.allorigins.win/raw?url=' + encodeURIComponent('https://pro.rajaongkir.com/api/city?key=7f752d10400f15469148e8a1802d311f&province=' + event.target.dataset.idprovince))
      );
      
      this.cancellableCity.promise.then((resultCity) => {
        let countTown = 0,
            countRegency = 0;

        resultCity.data.rajaongkir.results.forEach((valCit, indCit) => {
          listCity.push({
            value: valCit.city_id,
            label: valCit.city_name,
            type: valCit.type,
            district: [],
            open: false
          });

          if(valCit.type === 'Kota'){
            countTown++
          }else{
            countRegency++;
          }
        });
        
        theProvince.city = listCity;
        theProvince.town = countTown;
        theProvince.regency = countRegency;
        theProvince.loading = false;
        
        const beforeSet = JSON.parse(JSON.stringify(listProvince));

        this.setState({
          province: beforeSet,
          countCit: this.state.countCit + resultCity.data.rajaongkir.results.length,
        });
      }).catch((error) => {
        console.error(error);
        toast.error(() => (<>{error.code}<span>{error.message}</span></>));
        
        theProvince.open = false;
        theProvince.loading = false;
  
        this.setState({ province: listProvince });
      });
    }
  }

  openDistrict(event){
    let listDistrict = [],
        listProvince = [...this.state.province],
        theCity = '';

    const indexProvince = listProvince.findIndex(x => x.value === event.target.dataset.idprovince),
          indexCity = listProvince[indexProvince].city.findIndex(x => x.value === event.target.dataset.idcity);

    theCity = listProvince[indexProvince].city[indexCity];

    if(theCity.district.length){
      if(theCity.open){
        theCity.open = false;
      }else{
        theCity.open = true;
      }
      
      this.setState({ province: listProvince });
    }else{
      theCity.open = true;
      theCity.loading = true;

      this.setState({ province: listProvince });
  
      this.cancellableDistrict = makeCancellablePromise(
        axios.get('https://api.allorigins.win/raw?url=' + encodeURIComponent('https://pro.rajaongkir.com/api/subdistrict?key=7f752d10400f15469148e8a1802d311f&city=' + event.target.dataset.idcity))
      );
      
      this.cancellableDistrict.promise.then((resultDistrict) => {
        resultDistrict.data.rajaongkir.results.forEach((valDis, indDis) => {
          listDistrict.push({
            value: valDis.subdistrict_id,
            label: valDis.subdistrict_name,
            postal: valDis.postal_code
          });
        });
        
        theCity.district = listDistrict;
        theCity.loading = false;

        const beforeSet = JSON.parse(JSON.stringify(listProvince));
  
        this.setState({
          province: beforeSet,
          countDis: this.state.countDis + resultDistrict.data.rajaongkir.results.length
        });
      }).catch((error) => {
        console.error(error);
        toast.error(() => (<>{error.code}<span>{error.message}</span></>));
        
        theCity.open = false;
        theCity.loading = false;
  
        this.setState({ province: listProvince });
      });
    }
  }

  clearSearch(){
    this.setState({ search: '' });
  }

  renderLoading(text){
    return(
      <div className="loading">
        <ul>
          <li><div></div></li>
          <li><div></div></li>
          <li><div></div></li>
          <li><div></div></li>
          <li><div></div></li>
          <li><div></div></li>
          <li><div></div></li>
          <li><div></div></li>
          <li><div></div></li>
        </ul>
        {text}...
      </div>
    );
  }

  render(){
    let search = this.state.search ? 'Found on' : 'Cached',
        pro = this.state.countProSearch ? `${this.state.countProSearch} Province` : '',
        cit = this.state.countCitSearch ? `${this.state.countCitSearch} District` : '',
        dis = this.state.countDisSearch ? `${this.state.countDisSearch} Subdistrict` : '';

    return(
      <>
        <Helmet>
          <title>
            Subdivision {`${search} ${pro} ${cit} ${dis}`}
          </title>
        </Helmet>

        <header>
          <a href="/">
            <Logo />
          </a>
          <div>
            {this.state.search ? (
              <button type="button" onClick={this.clearSearch}><RiCloseCircleFill /></button>
            ) : ''}
            {/* <label>Search Province, City, Regency or Subdistrict (only cached)</label> */}
            <input type="text" name="search" placeholder="Search province, city, regency or subdistrict (only cached) here..." value={this.state.search} onChange={this.inputChange} className={this.state.search ? 'has-val' : ''} />
            <span className="hash">
              <RiSearchLine />
            </span>
          </div>
        </header>

        <div className="pad-top"></div>

        {this.state.pageLoad ? this.renderLoading('Load Province') : this.state.provinceSearch.length ? (
          <ul className="list-province">
            {this.state.provinceSearch.map((valPro, indPro) => (
              <li key={valPro.value} className={valPro.open ? 'open' : ''}>
                <button type="button" onClick={this.openCity} data-idprovince={valPro.value}>
                  {valPro.label}{this.state.search ? valPro.city.length ? (
                    <ul>
                      <li>
                        <strong>{valPro.city.length}</strong> district
                      </li>
                    </ul>
                  ) : '' : valPro.city.length ? (
                    <ul>
                      <li>
                        <strong>{valPro.town + valPro.regency}</strong> district
                      </li>
                      <li>
                        <strong>{valPro.town}</strong> cities
                      </li>
                      <li>
                        <strong>{valPro.regency}</strong> regency
                      </li>
                    </ul>
                  ) : ''}
                  <div></div>
                  <RiArrowDownSLine />
                </button>
                
                {valPro.loading ? this.renderLoading(`Load City in ${valPro.label}`) : valPro.open ? (
                  <ul>
                    {valPro.city.map((valCit, indCit) => (
                      <li key={`${valPro.value}-${valCit.value}`} className={valCit.open ? 'open' : ''}>
                        <button type="button" onClick={this.openDistrict} data-idprovince={valPro.value} data-idcity={valCit.value}>
                          {valCit.type} {valCit.label}{valCit.district.length ? (
                            <ul>
                              <li>
                                <strong>{valCit.district.length}</strong> subdistrict
                              </li>
                            </ul>
                          ) : ''}
                          <div></div>
                          <RiArrowDownSLine />
                        </button>

                        {valCit.loading ? this.renderLoading(`Load Subdistrict in ${valCit.label}`) : valCit.open ? (
                          <ul>
                            {valCit.district.map((valDis, indDis) => (
                              <li key={`${valPro.value}-${valCit.value}-${valDis.value}`}>
                                <div>
                                  {valDis.label}
                                  <span>{valDis.postal}</span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        ) : ''}
                      </li>
                    ))}
                  </ul>
                ) : ''}
              </li>
            ))}
          </ul>
        ) : (
          <div className="empty">
            <span>Search Empty</span>
            No result found for "<strong>{this.state.search}</strong>", please change the keywords.
          </div>
        )}
        
        <footer>
          <a href="/">Subdivision</a> &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} by <a href="https://nabilamerthabit.com" target="_blank" rel="noreferrer">Nabil Amer Thabit</a> is licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer">CreativeCommons BY-NC-SA 4.0 <RiCreativeCommonsLine /> <RiCreativeCommonsByLine /> <RiCreativeCommonsNcLine /> <RiCreativeCommonsSaLine /></a>
          <div>Subdivison of province, district & subdistrict data from <a target="_blank" rel="noreferrer" href="https://rajaongkir.com">RajaOngkir</a>. We do not guarantee the accuracy or completeness of the information provided.</div>
        </footer>
      </>
    );
  }
}

export default App;
